<template>
  <div class="d-flex flex-column justify-content-between">
    <!-- Titulo -->
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense>
            <v-col
              cols="12"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("Payroll") }}</h2>
                <v-tooltip top :max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("tooltipUsers1") }}
                    <br />
                    {{ $t("tooltipUsers2") }}
                  </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>
          <!-- Filtros -->
          <v-row dense class="mt-4 d-flex flex-row justify-space-between">
            <v-col cols="12" md="8">
              <v-row dense>
                <v-col md="4" cols="12">
                  <v-text-field
                    :label="$t('Search')"
                    :prepend-inner-icon="icons.mdiMagnify"
                    outlined
                    dense
                    v-model="search"
                    clearable
                    @keyup.esc="search = ''"
                    :disabled="table.tableLoading"
                  ></v-text-field>
                </v-col>
                <v-col md="7" cols="12">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    multiple
                    :items="[
                      `${$t('Active')}`,
                      `${$t('Waiting for approval')}`,
                      `${$t('Inactive')}`,
                    ]"
                    v-model="selectionFilter"
                    :disabled="table.tableLoading"
                  >
                    <template v-slot:selection="{ item }" class="cursor-hover">
                      <v-chip
                        v-if="item === 'Activo' || item === 'Active'"
                        class="v-chip-light-bg success--text"
                        color="accent"
                        close
                        @click:close="remove(item)"
                      >
                        <span>{{ item }}</span>
                      </v-chip>
                      <v-chip
                        v-if="item === 'Inactivo' || item === 'Inactive'"
                        class="v-chip-light-bg error--text"
                        color="accent"
                        close
                        @click:close="remove(item)"
                      >
                        <span>{{ item }}</span>
                      </v-chip>
                      <v-chip
                        v-if="
                          item === 'Esperando aprobación' ||
                          item === 'Waiting for approval'
                        "
                        class="v-chip-light-bg warning--text"
                        color="warning"
                        close
                        @click:close="remove(item)"
                      >
                        <span>{{ item }}</span>
                      </v-chip>
                    </template>

                    <template
                      v-slot:item="{ active, item, attrs, on }"
                      class="cursor-hover"
                    >
                      <v-list-item
                        v-on="on"
                        v-bind="attrs"
                        #default="{ active }"
                      >
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-chip
                              v-if="item === 'Activo' || item === 'Active'"
                              class="v-chip-light-bg success--text cursor-hover"
                              color="accent"
                            >
                              <span>{{ item }}</span>
                            </v-chip>
                            <v-chip
                              v-if="item === 'Inactivo' || item === 'Inactive'"
                              class="v-chip-light-bg error--text cursor-hover"
                              color="accent"
                            >
                              <span>{{ item }}</span>
                            </v-chip>
                            <v-chip
                              v-if="
                                item === 'Esperando aprobación' ||
                                item === 'Waiting for approval'
                              "
                              class="v-chip-light-bg warning--text cursor-hover"
                              color="warning"
                            >
                              <span>{{ item }}</span>
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="d-flex flex-row justify-end align-start"
            >
              <vue-excel-xlsx
                v-if="!new_"
                :data="computedFilteredUsersExcel"
                :columns="table.headersExport"
                :file-name="`${$t('Payroll')}` + '-' + `${today}`"
                :file-type="'xlsx'"
                :sheet-name="$t('Payroll')"
                :style="'width: 100%;'"
              >
                <v-btn
                  block
                  outlined
                  color="primary"
                  :disabled="table.tableLoading"
                >
                  <v-icon> {{ icons.mdiDownload }}</v-icon>
                  <span class="ml-2">{{ $t("Export") }}</span>
                </v-btn>
              </vue-excel-xlsx>
            </v-col>
          </v-row>

          <!-- tabla -->
          <v-row dense class="mt-4">
            <v-col cols="12">
              <DataTable
                :loading="table.tableLoading"
                :headers="table.headers"
                :items="computedFilteredUsers"
                :search="search"
                v-if="!new_"
                @refresh="handleRefresh()"
                :selectionFilter="selectionFilter"
                :refresher="refresher"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Placeholder de nuevos -->
    <v-row v-if="new_" class="custom-margin">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <v-img
          src="../Users/assets/typography_es.png"
          width="300"
          v-if="!$vuetify.theme.isDark"
        ></v-img>
        <v-img
          src="../Users/assets/typography_es_dark.png"
          width="300"
          v-else
        ></v-img>
      </v-col>
    </v-row>

    <!-- Agregar usuarios -->
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-row justify-md-end justify-center align-center"
      >
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" rounded v-bind="attrs" v-on="on">
              <v-icon style="color: #ffffff !important">{{
                icons.mdiPlus
              }}</v-icon>
              <span class="ml-2">{{ $t("Add to payroll") }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="toggleModal()">
              <v-list-item-title>{{$t('bulk.unitary')}}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="toggleBulkAddusersModal()">
              <v-list-item-title>{{$t('bulk.bulk')}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="1">
        <v-switch v-model="new_" label="nuevo" />
      </v-col>
    </v-row> -->
    <!-- <code>
      {{ table.users }}
    </code> -->
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
  mdiPlus,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";
import DataTable from "./parts/DataTable.vue";
import Modal from "./parts/Modal.vue";
import ModalEN from "./parts/ModalEN.vue";
import BulkAddUsersModal from './parts/BulkAddUsersModal.vue'

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiDownload,
        mdiPlus,
      },
      selectionFilter: [this.$t("Active")],
      new_: false,
      table: {
        users: [],
        usersExcel: [],
        tableLoading: false,
        headers: [
          {
            text: "USER ID",
            value: "unique_id",
            align: "start",
            sortable: false,
          },
          {
            text: "NAME",
            value: "full_name",
            align: "start",
            sortable: !false,
          },
          {
            text: "PHONE",
            value: "phone",
            align: "start",
            sortable: !false,
          },
          { text: "EMAIL", value: "email", align: "start", sortable: !false },
          { text: "BALANCE", value: "balance", align: "end" },
          { text: "STATUS", value: "status", align: "center" },
          { text: "SOBREGIRO", value: "overdraft", align: "center" },
          {
            text: "ACCIÓN",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ],
        headersExport: [
          { label: this.$t("USER ID"), field: "unique_id" },
          { label: this.$t("NAME"), field: "full_name" },
          { label: this.$t("PHONE"), field: "phone" },
          { label: this.$t("EMAIL"), field: "email" },
          { label: this.$t("BALANCE"), field: "balance" },
          { label: this.$t("STATUS"), field: "status" },
          { label: this.$t("OVERDRAFT"), field: "overdraft" },
        ],
      },
      search: "",
      today: "",
      refresher: false,
    };
  },
  methods: {
    ...mapActions("auth", ["meNotLoading"]),
    remove(item) {
      const index = this.selectionFilter.indexOf(item);
      if (index >= 0) this.selectionFilter.splice(index, 1);
    },
    async toggleModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "50%",
        transition: "dialog-top-transition",
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(Modal, params);
        if (!dialogInstance) {
          dialogInstance.close;
        } else {
          dialogInstance.close;
          this.getUsers();
          this.selectionFilter = [this.$t("Waiting for approval")];
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(ModalEN, params);
        if (!dialogInstance) {
          dialogInstance.close;
        } else {
          dialogInstance.close;
          this.getUsers();
          this.selectionFilter = [this.$t("Waiting for approval")];
        }
      }
    },
    async getUsers() {
      this.table.tableLoading = true;
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/all_users`
        );
        if (!!data) {
          if (data.users.length >= 1) {
            this.new_ = false;
            this.table.users = [];
            data.users.forEach((user) => {
              let obj = {
                unique_id: user.unique_id,
                full_name: `${user.first_name} ${user.last_name}`,
                picture: user.picture,
                initials: `${user.first_name[0]}${user.last_name[0]}`,
                phone: `${user.country_phone_code}${user.phone}`,
                email: user.email,
                balance:
                  this.handleUserStatus(user, data.corporate_id) ===
                  this.$t("Active")
                    ? user.corporate_wallet_limit
                      ? user.corporate_wallet_limit
                      : 0
                    : "--",
                status: this.handleUserStatus(user, data.corporate_id),
                overdraft:
                  this.handleUserStatus(user, data.corporate_id) ===
                  this.$t("Active")
                    ? user.is_allow_overdraft
                      ? true
                      : false
                    : "--",
                _id: user._id,
              };
              let objExcel = {
                unique_id: user.unique_id,
                full_name: `${user.first_name} ${user.last_name}`,
                phone: `${user.country_phone_code}${user.phone}`,
                email: user.email,
                balance:
                  this.handleUserStatus(user, data.corporate_id) ===
                  this.$t("Active")
                    ? user.corporate_wallet_limit
                      ? user.corporate_wallet_limit
                      : 0
                    : "--",
                status: this.handleUserStatus(user, data.corporate_id),
                overdraft:
                  this.handleUserStatus(user, data.corporate_id) ===
                  this.$t("Active")
                    ? user.is_allow_overdraft
                      ? this.$t("Active")
                      : this.$t("Inactive")
                    : "--",
                _id: user._id,
              };
              this.table.users.push(obj);
              this.table.usersExcel.push(objExcel);
            });
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();
      this.table.tableLoading = false;
    },
    handleRefresh() {
      this.getUsers();
      this.refresher = !this.refresher;
    },
    handleUserStatus(user, corporate_id) {
      if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "0"
      ) {
        return this.$t("Active");
      } else if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "1"
      ) {
        return this.$t("Waiting for approval");
      } else if (
        user.old_corporate_ids.toString().includes(corporate_id.toString())
      ) {
        return this.$t("Inactive");
      }
    },
    async toggleBulkAddusersModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        hideCancel: true,
        persistent: true,
      };
      const dialogInstance = await this.$dialog.showAndWait(
        BulkAddUsersModal,
        params
      );
      if (dialogInstance) {
        this.handleRefresh()
      }
    },
  },
  created() {
    this.getUsers();
    let today = new Date();
    this.today = this.$moment(today).add(-4, "hours").toISOString();
    if (this.$route.query.waiting_approval_trip === 1) {
      this.selectionFilter = [this.$t("Waiting for approval")];
    }
  },
  computed: {
    computedFilteredUsers() {
      let filtered = this.table.users
        .filter((user) => this.selectionFilter.includes(user.status))
        .sort((a, b) => {
          return a.full_name.localeCompare(b.full_name);
        });

      return filtered;
    },
    computedFilteredUsersExcel() {
      let filtered = this.table.usersExcel
        .filter((user) => this.selectionFilter.includes(user.status))
        .sort((a, b) => {
          return a.full_name.localeCompare(b.full_name);
        });
      return filtered;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-margin {
  margin-top: 75px !important;
}
.icon-white {
  color: #ffffff !important;
}
.cursor-hover:hover {
  cursor: pointer;
}
</style>
