<template>
  <v-card tile class="">
    <template v-if="step === 1">
      <v-card-text class="text-center">
        <v-container class="pa-12">
          <v-row dense class="d-flex flex-row justify-center align-center">
            <v-col cols="12">
              <h2>
                {{
                  ES
                    ? "Operaciones masivas: agregar usuarios"
                    : "Bulk operations: add users"
                }}
              </h2>
            </v-col>
            <v-col cols="12">
              <p class="" v-if="ES">
                Agregar masivamente (en lote) usuarios a tu nómina
              </p>
              <p v-else>Massively add (in bulk) users to your payroll</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p>
                {{
                  ES
                    ? "Debes rellenar el archivo siguiendo el formato propuesto en el esquema, en donde cada usuario corresponde a un número de teléfono"
                    : "You must fill the file following the proposed format in the schema, in which every single user corresponds to a telephone number"
                }}
              </p>
              <v-row dense class="d-flex justify-center"
                ><v-col md="4" cols="12">
                  <span class="text-caption"> (Ejemplo) </span>
                </v-col></v-row
              >
              <v-row class="d-flex justify-center">
                <v-col md="4" cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">Teléfono</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>4122225889</td>
                        </tr>
                        <tr>
                          <td>4142225889</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <br />
              <v-row class="d-flex flex-row justify-center">
                <v-col cols="12" md="4">
                  <vue-excel-xlsx
                    :data="baseExport"
                    :columns="baseHeaders"
                    file-name="add_users_payroll"
                    :file-type="'xlsx'"
                    sheet-name="add_users"
                    :style="'width: 100%;'"
                  >
                    <a
                      >{{ ES ? "Descargar esquema" : "Download schema" }}
                      <v-icon>{{ icons.mdiDownload }}</v-icon></a
                    >
                  </vue-excel-xlsx>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p>
                {{
                  ES
                    ? "Una vez rellenado el archivo, debes subirlo utilizando el siguiente input"
                    : "Once the file is filled, you must submit it using the following input"
                }}
              </p>
            </v-col>
          </v-row>
          <v-row
            class="
              d-flex
              flex-md-row flex-column-reverse
              justify-center
              align-center
            "
          >
            <v-col cols="12" md="4">
              <v-file-input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :label="ES ? 'Subir esquema' : 'Upload schema'"
                outlined
                dense
                @change="handleInput"
                v-model="file"
              >
                <template #append-outer v-if="parsedJSON" class="">
                  <span class="" style="margin-top: -6px">
                    <v-dialog v-model="dialog" width="600px" persistent>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-on="on" v-bind="attrs">
                          <v-icon color="primary">
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">{{
                            ES
                              ? "Detalle de carga de usuarios"
                              : "User upload detail"
                          }}</span>
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <p class="success--text font-weight-bold">
                                {{
                                  ES
                                    ? "Usuarios a buscar en la base de datos de Ridery"
                                    : "Users to search on Ridery's database"
                                }}
                              </p>
                              <p>
                                {{ ES ? "Total usuarios:" : "Total users:" }}
                                <span class="font-weight-bold">{{
                                  parsedJSON.length
                                }}</span>
                              </p>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-center">#</th>
                                      <th class="text-center">Teléfono</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(parsed, index) in parsedJSON"
                                      :key="index"
                                    >
                                      <td class="text-center">
                                        {{ index + 1 }}
                                      </td>
                                      <td class="text-center">
                                        {{ parsed["Telefono"] }} ✅
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="
                              Boolean(duplicatesPhones) ||
                              Boolean(wrongFormat.length)
                            "
                          >
                            <v-col cols="12"> </v-col>
                            <v-col cols="12">
                              <p class="error--text font-weight-bold">
                                {{
                                  ES ? "Usuarios erróneos" : "Users with errors"
                                }}
                              </p>
                              <p v-if="Boolean(duplicatesPhones)">
                                {{
                                  ES ? "Total duplicados:" : "Total duplicated:"
                                }}
                                <span class="font-weight-bold">{{
                                  duplicatesPhones
                                }}</span>
                              </p>
                              <p v-if="Boolean(wrongFormat.length)">
                                {{
                                  ES
                                    ? "Total formatos erróneos:"
                                    : "Total format errors"
                                }}
                                <span class="font-weight-bold">{{
                                  wrongFormat.length
                                }}</span>
                              </p>
                            </v-col>
                          </v-row>
                          <v-row v-if="Boolean(wrongFormat.length)">
                            <v-col cols="12">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-center">#</th>
                                      <th class="text-center">Teléfono</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(parsed, index) in wrongFormat"
                                      :key="index"
                                    >
                                      <td class="text-center">
                                        {{ index + 1 }}
                                      </td>
                                      <td class="text-center">
                                        {{ parsed["Telefono"] }} ❌
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="dialog = false">
                            {{ ES ? "Volver" : "Go back" }}
                          </v-btn>
                          <v-btn
                            color="success"
                            text
                            @click="parsedJSON && handleStep2()"
                          >
                            {{ ES ? "Aceptar y buscar" : "Accept and search" }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </span>
                </template>
              </v-file-input>
              <xlsx-read :file="file" class="d-none">
                <xlsx-json @parsed="handleParsed">
                  <template #default="{ collection }">
                    <div>
                      {{ collection }}
                    </div>
                  </template>
                </xlsx-json>
              </xlsx-read>
            </v-col>
          </v-row>
          <v-row
            class="
              d-flex
              flex-md-row flex-column-reverse
              justify-center
              align-center
            "
            dense
          >
            <v-col cols="6" md="4">
              <v-btn
                :disabled="!parsedJSON"
                color="primary"
                @click="dialog = true"
              >
                {{ ES ? "Continuar" : "Continue" }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row
            class="
              d-flex
              flex-md-row flex-column-reverse
              justify-center
              align-center
            "
          >
            <v-col cols="12" md="12">
              <a
                class="text-decoration-underline text-lg"
                @click="handleClose()"
              >
                {{ ES ? "Cancelar" : "Cancel" }}
              </a>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
    <template v-if="step === 2">
      <v-card-text class="text-center">
        <v-container class="pa-12">
          <template v-if="loading">
            <v-row>
              <v-col>
                <v-progress-circular
                  class="mt-10 mb-10"
                  indeterminate
                  :size="64"
                  color="primary"
                />
                <p class="text-xl">
                  {{
                    ES
                      ? "Buscando usuarios en base de datos de Ridery..."
                      : "Searching users in Ridery's database"
                  }}
                </p>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row dense class="d-flex flex-row justify-center align-center">
              <v-col cols="12">
                <h2>
                  {{
                    ES
                      ? "Operaciones masivas: sobregiro"
                      : "Bulk operations: overdraft"
                  }}
                </h2>
              </v-col>
            </v-row>
            <h2>{{ ES ? "Resumen" : "Summary" }}</h2>
            <h3>
              {{
                ES
                  ? "En esta vista puedes revisar el resultado de la operación"
                  : "In this view you can check the result of the operation"
              }}
            </h3>
            <br />
            <h4>
              {{ ES ? "Total usuarios" : "Total users" }}
              <span class="success--text">{{
                ES
                  ? "encontrados en la base de datos de Ridery:"
                  : "found in Ridery's database:"
              }}</span>
              {{ usersFound.length ? usersFound.length : 0 }}
            </h4>
            <br />
            <template v-if="!Boolean(usersFound.length)">
              <v-row>
                <v-col cols="12" class="d-flex flex-column align-center">
                  <v-img src="@/views/Balance/assets/notFound.png" width="150">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <br />
              <p class="error--text">
                {{
                  ES
                    ? "No se encontró ningún usuario en la base de datos de Ridery"
                    : "No user was found in Ridery's database"
                }}
              </p>
            </template>
            <br />
            <v-row v-if="Boolean(usersFound.length)">
              <v-col cols="12">
                <v-data-table
                  :headers="ES ? headersES : headersEN"
                  :disable-sort="false"
                  :items="usersFound"
                  class="dataTable"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
                  }"
                  mobile-breakpoint="0"
                  :dense="true"
                >
                </v-data-table>
              </v-col>
            </v-row>
            <v-row
              class="
                d-flex
                flex-md-row flex-column-reverse
                justify-center
                align-center
              "
              dense
            >
              <v-col cols="6" md="4">
                <v-btn color="primary" @click="step--" outlined>{{
                  ES ? "Volver" : "Go back"
                }}</v-btn>
              </v-col>
              <v-col cols="6" md="4">
                <v-btn
                  color="primary"
                  @click="handleStep3()"
                  :disabled="!Boolean(usersFound.length)"
                >
                  {{ ES ? "Agregar" : "Add" }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="
                d-flex
                flex-md-row flex-column-reverse
                justify-center
                align-center
              "
            >
              <v-col cols="12" md="12">
                <a
                  class="text-decoration-underline text-lg"
                  @click="handleClose()"
                >
                  {{ ES ? "Cancelar" : "Cancel" }}
                </a>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card-text>
    </template>
    <template v-if="step === 3">
      <v-card-text class="text-center">
        <v-container class="pa-12">
          <template v-if="loading">
            <v-row>
              <v-col>
                <v-progress-circular
                  class="mt-10 mb-10"
                  indeterminate
                  :size="64"
                  color="primary"
                />
                <p class="text-xl">
                  {{
                    ES
                      ? "Validando usuarios en base de datos de Ridery para agregar al panel corporativo..."
                      : "Validating users in Ridery's database to add to the corporate panel..."
                  }}
                </p>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row dense class="d-flex flex-row justify-center align-center">
              <v-col cols="12">
                <h2>
                  {{
                    ES
                      ? "Operaciones masivas: sobregiro"
                      : "Bulk operations: overdraft"
                  }}
                </h2>
              </v-col>
            </v-row>
            <h2>{{ ES ? "Resumen" : "Summary" }}</h2>
            <h3>
              {{
                ES
                  ? "En esta vista puedes revisar el resultado de la operación"
                  : "In this view you can check the result of the operation"
              }}
            </h3>
            <br />
            <v-row v-if="Boolean(usersSuccess.length)" class="mb-4">
              <v-col cols="12" class="d-flex flex-column align-center">
                <v-img src="@/views/Balance/assets/found.png" width="150">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <h4>
              {{ ES ? "Total usuarios" : "Total users" }}
              <span class="success--text">{{
                ES
                  ? "agregados al panel corporativo:"
                  : "added to the corporate panel:"
              }}</span>
              {{ usersSuccess.length ? usersSuccess.length : 0 }}
            </h4>
            <br />
            <v-row v-if="Boolean(usersSuccess.length)">
              <v-col cols="12">
                <v-data-table
                  :headers="ES ? headersES : headersEN"
                  :disable-sort="false"
                  :items="usersSuccess"
                  class="dataTable"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
                  }"
                  mobile-breakpoint="0"
                  :dense="true"
                >
                </v-data-table>
              </v-col>
            </v-row>
            <template v-if="usersFail && usersFail.length > 0">
              <v-row>
                <v-col cols="12" class="d-flex flex-column align-center">
                  <v-img src="@/views/Balance/assets/notFound.png" width="150">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <br />
              <h4>
                {{ ES ? "Total usuarios" : "Total users" }}
                <span class="error--text">{{
                  ES
                    ? "no agregados al panel corporativo:"
                    : "not added to the corporate panel:"
                }}</span>
                {{ usersFail.length ? usersFail.length : 0 }}
              </h4>
              <br />
              <v-row v-if="Boolean(usersFail.length)">
                <v-col cols="12">
                  <v-data-table
                    :headers="ES ? headersESFail : headersESFail"
                    :disable-sort="false"
                    :items="usersFail"
                    class="dataTable"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
                    }"
                    mobile-breakpoint="0"
                    :dense="true"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </template>
            <v-row
              class="
                d-flex
                flex-md-row flex-column-reverse
                justify-center
                align-center
              "
              dense
            >
              <v-col cols="6" md="4">
                <v-btn color="primary" @click="step--" outlined>{{
                  ES ? "Volver" : "Go back"
                }}</v-btn>
              </v-col>
              <v-col cols="6" md="4">
                <v-btn color="primary" @click="handleCloseSuccess()">
                  {{ ES ? "Aceptar" : "Accept" }}</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card-text>
    </template>
  </v-card>
</template>
<script>
import { mdiWhatsapp, mdiFileFind } from "@mdi/js";
import {
  mdiMagnify,
  mdiAlert,
  mdiDownload,
  mdiInformationOutline,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import { XlsxRead, XlsxJson } from "vue-xlsx";

export default {
  props: {
    item: {
      type: Array,
    },
  },
  components: {
    XlsxRead,
    XlsxJson,
  },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
        mdiFileFind,
        mdiDownload,
        mdiInformationOutline,
      },
      headersEN: [
        { text: "USER ID", value: "unique_id", align: "start" },
        { text: "NAME", value: "full_name", align: "start" },
        { text: "PHONE", value: "phone", align: "start" },
        { text: "EMAIL", value: "email", align: "start" },
      ],
      headersES: [
        { text: "ID USUARIO", value: "unique_id", align: "start" },
        { text: "NOMBRE", value: "full_name", align: "start" },
        { text: "TELÉFONO", value: "phone", align: "start" },
        { text: "CORREO", value: "email", align: "start" },
      ],
      headersESFail: [
        { text: "ID USUARIO", value: "unique_id", align: "start" },
        { text: "NOMBRE", value: "full_name", align: "start" },
        { text: "TELÉFONO", value: "phone", align: "start" },
        { text: "CORREO", value: "email", align: "start" },
        { text: "RAZONES", value: "reason_error_code", align: "center" },
      ],
      step: 1,
      loading: false,
      usersFound: [],
      usersSuccess: [],
      usersFail: [],
      baseExport: [{ Telefono: 4141112233 }, { Telefono: 4161112233 }],
      baseHeaders: [{ label: "Telefono", field: "Telefono" }],
      file: null,
      parsedJSON: null,
      dialog: false,
      duplicatesPhones: 0,
      wrongFormat: [],
      file: null,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["me"]),
    handleClose() {
      this.$emit("submit", null);
    },
    handleCloseSuccess() {
      this.$emit("submit", true);
    },
    tableCellBalance(value) {
      if (value > 0) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return ``;
    },
    handleUserStatus(user, corporate_id) {
      if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "0"
      ) {
        return this.ES ? "Activo" : "Active";
      } else if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "1"
      ) {
        return this.ES ? "Esperando aprobación" : "Waiting for approval";
      } else if (
        user.old_corporate_ids.toString().includes(corporate_id.toString())
      ) {
        return this.ES ? "Inactivo" : "Inactive";
      }
    },
    handleInput(event) {
      if (event) {
        this.file = event.size ? event : null;
        return;
      }
      this.parsedJSON = null;
    },
    handleParsed(array) {
      if (!array) {
        this.parsedJSON = null;
        return;
      }
      const noDuplicates = array.filter((obj, index) => {
        return (
          index === array.findIndex((o) => obj["Telefono"] === o["Telefono"])
        );
      });

      this.duplicatesPhones = array.length - noDuplicates.length;

      let onlyNumbersNoDuplicates = [];
      let wrongFormat = [];
      noDuplicates.forEach((el) => {
        if (this.containsOnlyNumbers(el["Telefono"])) {
          onlyNumbersNoDuplicates.push(el);
        } else {
          wrongFormat.push(el);
        }
      });

      this.wrongFormat = wrongFormat;

      this.parsedJSON = Boolean(onlyNumbersNoDuplicates.length)
        ? onlyNumbersNoDuplicates
        : null;
    },
    async handleStep2() {
      this.dialog = false;
      this.step++;
      this.loading = true;
      let telfs = [];
      this.parsedJSON.forEach((el) => {
        telfs.push(el["Telefono"]);
      });
      try {
        const params = {
          users_phones: telfs,
        };
        const { data } = await axios.post("/find_user", params);
        if (!!data.success) {
          this.usersFound = [];
          data.users.forEach((user) => {
            let obj = {
              unique_id: user.unique_id,
              full_name: `${user.first_name} ${user.last_name}`,
              picture: user.picture,
              initials: `${user.first_name[0]}${user.last_name[0]}`,
              phone: `${user.country_phone_code}${user.phone}`,
              email: user.email,
              balance:
                this.handleUserStatus(user, this._id) ===
                (this.ES ? "Activo" : "Activo")
                  ? user.corporate_wallet_limit
                    ? user.corporate_wallet_limit
                    : 0
                  : "--",
              status: this.handleUserStatus(user, this._id),
              overdraft:
                this.handleUserStatus(user, this._id) ===
                (this.ES ? "Activo" : "Active")
                  ? user.is_allow_overdraft
                    ? true
                    : false
                  : "--",
              _id: user._id,
            };
            this.usersFound.push(obj);
          });
        } else {
          throw new Error("Error in find user");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    async handleStep3() {
      this.step++;
      this.loading = true;
      let ids = [];
      this.usersFound.forEach((el) => {
        ids.push(el._id);
      });
      try {
        const params = {
          users_ids: ids,
        };
        const { data } = await axios.post("/send_request", params);
        if (!!data.status) {
          this.usersSuccess = [];
          data.users_success.forEach((user) => {
            let obj = {
              unique_id: user.user_detail.unique_id,
              full_name: `${user.user_detail.first_name} ${user.user_detail.last_name}`,
              picture: user.user_detail.picture,
              initials: `${user.user_detail.first_name[0]}${user.user_detail.last_name[0]}`,
              phone: `${user.user_detail.country_phone_code}${user.user_detail.phone}`,
              email: user.user_detail.email,
              _id: user.user_detail._id,
              reason_error_code: user.reason_error_code,
            };
            this.usersSuccess.push(obj);
          });
          this.usersFail = [];
          data.users_failed.forEach((user) => {
            let obj = {
              unique_id: user.user_detail.unique_id,
              full_name: `${user.user_detail.first_name} ${user.user_detail.last_name}`,
              picture: user.user_detail.picture,
              initials: `${user.user_detail.first_name[0]}${user.user_detail.last_name[0]}`,
              phone: `${user.user_detail.country_phone_code}${user.user_detail.phone}`,
              email: user.user_detail.email,
              _id: user.user_detail._id,
              reason_error_code: user.reason_error_code,
            };
            this.usersFail.push(obj);
          });
        } else {
          throw new Error("Error in send request");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    containsOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft", "_id"]),
    ES() {
      return this.$vuetify.lang.current === "es";
    },
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    min-height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px

.dataTable
  tbody tr:nth-of-type(even)
    background-color: rgba(58, 53, 65, 0.04) !important

  tbody tr:hover
    background-color: rgba(0, 5, 34, 0.08) !important

  tbody tr
    border-bottom: hidden !important
</style>
