<template>
  <v-card tile class="">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center">
      <v-container class="pa-12">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>Activate user</h2>
          </v-col>
          <v-col cols="12">
            <p class="">Would you like to activate the following user?</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :disable-sort="!false"
              :items="item"
              class="dataTable"
              :hide-default-footer="true"
            >
              <!-- <template #item="{ item }">
        <tr>
          <td v-for="(cell, i) in item" :key="i">
            <span v-if="i !== 10">
              {{ cell }}
            </span>
            <span v-else>

            </span>
          </td>
        </tr>
      </template> -->
              <template #item.balance="{ item }">
                <span
                  v-if="item.balance !== '--'"
                  :class="tableCellBalance(item.balance)"
                >
                  {{ item.balance | currency }}</span
                >
                <span v-else> {{ item.balance }} </span>
              </template>
              <template v-slot:item.status="{ value }">
                <v-chip
                  v-if="value === 'Active'"
                  class="v-chip-light-bg success--text"
                  color="accent"
                >
                  Active
                </v-chip>
                <v-chip
                  v-if="value === 'Inactive'"
                  class="v-chip-light-bg error--text"
                  color="accent"
                >
                  Inactive
                </v-chip>
                <v-chip
                  v-if="value === 'Waiting for approval'"
                  class="v-chip-light-bg warning--text"
                  color="warning"
                >
                  Waiting for approval
                </v-chip>
              </template>
              <template v-slot:item.overdraft="{ value }">
                <v-chip
                  v-if="value === true"
                  class="v-chip-light-bg success--text"
                  color="accent"
                >
                  Active
                </v-chip>
                <v-chip
                  v-if="value === false"
                  class="v-chip-light-bg error--text"
                  color="accent"
                >
                  Inactive
                </v-chip>
                <span v-if="value === '--'"> -- </span>
              </template>
              <!-- <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize"> Reset </v-btn>
                </template> -->
            </v-data-table>
          </v-col>
        </v-row>
        <v-row
          class="d-flex flex-md-row flex-column-reverse justify-center align-center"
        >
          <v-col cols="12" md="4">
            <v-btn color="primary" block outlined @click="handleClose()">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn color="primary" block @click="handleAdd()"> Accept </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import { mdiMagnify, mdiAlert } from "@mdi/js";
import { VMoney } from "v-money";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";

export default {
  directives: { money: VMoney },
  props: {
    item: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
      },
      alertError: false,
      price: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      headers: [
        { text: "USER ID", value: "unique_id", align: "start" },
        { text: "NAME", value: "full_name", align: "start" },
        { text: "PHONE", value: "phone", align: "start" },
        { text: "EMAIL", value: "email", align: "start" },
        { text: "BALANCE", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "OVERDRAFT", value: "overdraft", align: "start" },
      ],
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["me"]),
    async handleSearch() {
      this.alertError = true;
      await new Promise((r) => setTimeout(r, 5000));
      this.alertError = false;
    },
    handleClose() {
      this.$emit("submit", null);
    },
    async handleAdd() {
      this.setLoading(true);
      try {
        const { data } = await axios.post("/send_request", {
          id: this.item[0]._id,
        });
        if (!!data.success) {
          this.$dialog.notify.success(
            `${this.item[0].full_name} added, waiting for approval`
          );
          this.$emit("submit", true);
        } else {
          throw data.error_code;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    tableCellBalance(value) {
      if (value > 0) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return ``;
    },
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft"]),
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)
    // color: white
    // opacity: 1
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    min-height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px
</style>
