<template>
  <v-card tile class="">
    <template v-if="step === 1">
      <v-card-text class="text-center">
        <v-container class="py-12">
          <v-row dense class="d-flex flex-row justify-center align-center">
            <v-col cols="12">
              <h2>
                {{
                  ES
                    ? "Operaciones masivas: saldo"
                    : "Bulk operations: user balance"
                }}
              </h2>
            </v-col>
            <v-col cols="12">
              <p class="" v-if="ES">
                ¿Desea
                <span class="text-decoration-underline">{{
                  addBalance ? "agregar" : "restar"
                }}</span>
                saldo a los siguientes usuarios?
              </p>
              <p v-else>
                ¿Would you like to
                <span class="text-decoration-underline">{{
                  addBalance ? "add" : "cut"
                }}</span>
                user balance to the following users?
              </p>
              <p>
                <span class="font-weight-bold">Balance:</span>
                <span class="ml-2">{{ wallet | currency }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="ES ? headersES : headersEN"
                :disable-sort="false"
                :items="item"
                class="dataTable"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
                }"
                mobile-breakpoint="0"
                :dense="true"
              >
                <template #item.balance="{ item }">
                  <span v-if="item.balance !== '--'">
                    {{ item.balance | currency }}</span
                  >
                  <span v-else> {{ item.balance }} </span>
                </template>
                <template v-slot:item.status="{ value }">
                  <v-chip
                    v-if="value === 'Active' || value === 'Activo'"
                    class="v-chip-light-bg success--text"
                    color="accent"
                    small
                  >
                    {{ ES ? "Activo" : "Active" }}
                  </v-chip>
                </template>
                <template v-slot:item.overdraft="{ value }">
                  <v-chip
                    v-if="value === true"
                    class="v-chip-light-bg success--text"
                    color="accent"
                    small
                  >
                    {{ ES ? "Activo" : "Active" }}
                  </v-chip>
                  <v-chip
                    v-if="value === false"
                    class="v-chip-light-bg error--text"
                    color="accent"
                    small
                  >
                    {{ ES ? "Inactivo" : "Inactive" }}
                  </v-chip>
                  <span v-if="value === '--'"> -- </span>
                </template>
                <template #item.add_cut="{ value }">
                  <span :class="tableCellBalance(value)">
                    {{ value | currency }}</span
                  >
                </template>
                <template #item.add_cut_balance="{ value }">
                  <span> {{ value | currency }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" class="d-flex flex-row justify-end">
              <v-btn
                :outlined="!addBalance"
                rounded
                color="success"
                @click="addBalance = true"
                :disabled="wallet < 0"
                class="flex-color"
              >
                {{ ES ? "Agregar" : "Add" }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex flex-row justify-start">
              <v-btn
                :outlined="addBalance"
                rounded
                color="error"
                @click="addBalance = false"
                class="flex-color"
              >
                {{ ES ? "Restar" : "Cut" }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-center">
            <v-col cols="12" md="6" class="d-flex flex-column">
              <v-text-field
                :label="
                  addBalance
                    ? ES
                      ? 'Agregar saldo'
                      : 'Add user balance'
                    : ES
                    ? 'Restar saldo'
                    : 'Cut user balance'
                "
                outlined
                dense
                v-model.lazy="price"
                v-money="money"
                prefix="$"
                :error-messages="
                  parseFloat(price) !== 0 &&
                  computedDisabled &&
                  (ES
                    ? 'Nota: No se puede agregar por encima del balance corporativo'
                    : 'Note: You cannot add higher than the corporate balance')
                "
                :error="parseFloat(price) !== 0 && computedDisabled"
                @keydown="nameKeydown($event)"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-center" dense>
            <v-col cols="6">
              <span
                v-if="parseFloat(price) > 0 && !computedDisabled"
                class="d-flex flex-column"
              >
                <span>
                  <span class="font-weight-bold">
                    {{
                      ES
                        ? "Balance corporativo al final:"
                        : "Corporate balance at the end:"
                    }}</span
                  >
                  <span class="ml-2" v-if="addBalance">{{
                    (wallet - userBalancePayload) | currency
                  }}</span>
                  <span class="ml-2" v-if="!addBalance">{{
                    (wallet + Math.abs(userBalancePayload)) | currency
                  }}</span>
                </span>
                <span>
                  <span class="font-weight-bold" v-if="ES"
                    >Balance corporativo
                    {{ addBalance ? "agregado" : "restado" }} en usuarios:</span
                  >
                  <span class="font-weight-bold" v-else
                    >Corporate balance {{ addBalance ? "added" : "cut" }} in
                    users:</span
                  >
                  <span class="ml-2">{{ userBalancePayload | currency }}</span>
                </span>
              </span>
            </v-col>
          </v-row>
          <v-row
            class="
              d-flex
              flex-md-row flex-column-reverse
              justify-center
              align-center
            "
            dense
          >
            <v-col cols="12" md="4">
              <v-btn
                block
                :color="addBalance ? 'success' : 'error'"
                @click="handleStep1()"
                class="flex-color"
                :disabled="computedPrice === 0 || computedDisabled"
              >
                {{
                  addBalance ? (ES ? "Agregar" : "Add") : ES ? "Restar" : "Cut"
                }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row
            class="
              d-flex
              flex-md-row flex-column-reverse
              justify-center
              align-center
            "
          >
            <v-col cols="12" md="12">
              <a
                class="text-decoration-underline text-lg"
                @click="handleClose()"
              >
                {{ ES ? "Cancelar" : "Cancel" }}
              </a>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
    <template v-if="step === 2">
      <v-card-text class="text-center">
        <v-container class="pa-12">
          <template v-if="loading">
            <v-row>
              <v-col>
                <v-progress-circular
                  class="mt-10 mb-10"
                  indeterminate
                  :size="64"
                  color="primary"
                />
                <p class="text-xl">{{ ES ? "Enviando..." : "Sending..." }}</p>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row dense class="d-flex flex-row justify-center align-center">
              <v-col cols="12">
                <h2>
                  {{
                    ES
                      ? "Operaciones masivas: saldo"
                      : "Bulk operations: user balance"
                  }}
                </h2>
              </v-col>
            </v-row>
            <template v-if="!successRequest">
              <v-row>
                <v-col cols="12" class="d-flex flex-column align-center">
                  <v-img
                    src="@/views/Balance/assets/notFound.png"
                    width="250"
                    class="wobble-hor-bottom"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <br />
              <h2>{{ ES ? "¡Lo sentimos!" : "We are sorry!" }}</h2>
              <h3>
                {{
                  ES
                    ? "Algo sucedió, por favor intentar de nuevo"
                    : "Something happened, please try again"
                }}
              </h3>
              <br />
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" outlined @click="step--">
                    {{ ES ? "Volver" : "Go back" }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-if="successRequest">
              <v-row>
                <v-col cols="12" class="d-flex flex-column align-center">
                  <v-img
                    src="@/views/Balance/assets/found.png"
                    width="250"
                    class="wobble-hor-bottom"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <br />
              <h2>{{ ES ? "¡Éxito!" : "Success!" }}</h2>
              <h3>{{ ES ? "Saldo actualizado" : "User balance updated" }}</h3>
              <br />
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="ES ? headersESSuccess : headersENSuccess"
                    :disable-sort="false"
                    :items="usersSuccess"
                    class="dataTable"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
                    }"
                    mobile-breakpoint="0"
                    :dense="true"
                  >
                    <template #item.balance="{ item }">
                      <span
                        v-if="item.balance !== '--'"
                        :class="tableCellBalance(item.balance)"
                      >
                        {{ item.balance | currency }}</span
                      >
                      <span v-else> {{ item.balance }} </span>
                    </template>
                    <template v-slot:item.status="{ value }">
                      <v-chip
                        v-if="value === 'Active' || value === 'Activo'"
                        class="v-chip-light-bg success--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Activo" : "Active" }}
                      </v-chip>
                    </template>
                    <template v-slot:item.overdraft="{ value }">
                      <v-chip
                        v-if="value === true"
                        class="v-chip-light-bg success--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Activo" : "Active" }}
                      </v-chip>
                      <v-chip
                        v-if="value === false"
                        class="v-chip-light-bg error--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Inactivo" : "Inactive" }}
                      </v-chip>
                      <span v-if="value === '--'"> -- </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-if="usersFail.length > 0">
                <v-col cols="12">
                  <v-data-table
                    :headers="ES ? headersESSuccess : headersENSuccess"
                    :disable-sort="false"
                    :items="usersFail"
                    class="dataTable"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
                    }"
                    mobile-breakpoint="0"
                    :dense="true"
                  >
                    <template #item.balance="{ item }">
                      <span
                        v-if="item.balance !== '--'"
                        :class="tableCellBalance(item.balance)"
                      >
                        {{ item.balance | currency }}</span
                      >
                      <span v-else> {{ item.balance }} </span>
                    </template>
                    <template v-slot:item.status="{ value }">
                      <v-chip
                        v-if="value === 'Active' || value === 'Activo'"
                        class="v-chip-light-bg success--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Activo" : "Active" }}
                      </v-chip>
                    </template>
                    <template v-slot:item.overdraft="{ value }">
                      <v-chip
                        v-if="value === true"
                        class="v-chip-light-bg success--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Activo" : "Active" }}
                      </v-chip>
                      <v-chip
                        v-if="value === false"
                        class="v-chip-light-bg error--text"
                        color="accent"
                        small
                      >
                        {{ ES ? "Inactivo" : "Inactive" }}
                      </v-chip>
                      <span v-if="value === '--'"> -- </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" outlined @click="handleCloseSuccess()">
                    {{ ES ? "Volver" : "Go back" }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </template>
        </v-container>
      </v-card-text>
    </template>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import { mdiMagnify, mdiAlert } from "@mdi/js";
import { VMoney } from "v-money";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";

export default {
  directives: { money: VMoney },
  props: {
    item: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
      },
      price: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      headersEN: [
        { text: "USER ID", value: "unique_id", align: "start" },
        { text: "NAME", value: "full_name", align: "start" },
        { text: "PHONE", value: "phone", align: "start" },
        { text: "EMAIL", value: "email", align: "start" },
        { text: "INITIAL BALANCE", value: "balance", align: "start" },
        { text: "ADD/CUT", value: "add_cut", align: "start" },
        { text: "FINAL BALANCE", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "OVERDRAFT", value: "overdraft", align: "start" },
      ],
      headersES: [
        { text: "ID USUARIO", value: "unique_id", align: "start" },
        { text: "NOMBRE", value: "full_name", align: "start" },
        { text: "TELÉFONO", value: "phone", align: "start" },
        { text: "CORREO", value: "email", align: "start" },
        { text: "SALDO INICIAL", value: "balance", align: "start" },
        {
          text: "AGREGAR/RESTAR",
          value: "add_cut",
          align: "start",
          sortable: false,
        },
        {
          text: "SALDO FINAL",
          value: "add_cut_balance",
          align: "start",
          sortable: false,
        },
        { text: "STATUS", value: "status", align: "start", sortable: false },
        {
          text: "SOBREGIRO",
          value: "overdraft",
          align: "start",
          sortable: false,
        },
      ],
      headersESSuccess: [
        { text: "ID USUARIO", value: "unique_id", align: "start" },
        { text: "NOMBRE", value: "full_name", align: "start" },
        { text: "TELÉFONO", value: "phone", align: "start" },
        { text: "CORREO", value: "email", align: "start" },
        { text: "SALDO", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "SOBREGIRO", value: "overdraft", align: "start" },
      ],
      headersENSuccess: [
        { text: "USER ID", value: "unique_id", align: "start" },
        { text: "NAME", value: "full_name", align: "start" },
        { text: "PHONE", value: "phone", align: "start" },
        { text: "EMAIL", value: "email", align: "start" },
        { text: "USER BALANCE", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "OVERDRAFT", value: "overdraft", align: "start" },
      ],
      headersESFail: [
        { text: "ID USUARIO", value: "unique_id", align: "start" },
        { text: "NOMBRE", value: "full_name", align: "start" },
        { text: "TELÉFONO", value: "phone", align: "start" },
        { text: "CORREO", value: "email", align: "start" },
        { text: "SALDO", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "SOBREGIRO", value: "overdraft", align: "start" },
        { text: "ERROR", value: "reason_error_code", align: "start" },
      ],
      headersENFail: [
        { text: "USER ID", value: "unique_id", align: "start" },
        { text: "NAME", value: "full_name", align: "start" },
        { text: "PHONE", value: "phone", align: "start" },
        { text: "EMAIL", value: "email", align: "start" },
        { text: "USER BALANCE", value: "balance", align: "start" },
        { text: "STATUS", value: "status", align: "start" },
        { text: "OVERDRAFT", value: "overdraft", align: "start" },
        { text: "ERROR", value: "reason_error_code", align: "start" },
      ],
      addBalance: true,
      step: 1,
      loading: false,
      successRequest: false,
      usersSuccess: [],
      usersFail: [],
      customRules: [
        (v) =>
          (v && v <= this.wallet) || "El monto no puede ser mayor al wallet",
      ],
      userBalancePayload: 0,
    };
  },
  created() {
    this.item.forEach((el) => (el["add_cut"] = 0));
    this.item.forEach((el) => {
      let difference = el.balance + el.add_cut;
      el["add_cut_balance"] = difference > 0 ? difference : 0;
    });
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["me"]),
    handleClose() {
      this.$emit("submit", null);
    },
    handleCloseSuccess() {
      this.$emit("submit", true);
    },
    tableCellBalance(value) {
      if (value > 0) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return ``;
    },
    async handleStep1() {
      this.step++;
      this.loading = true;
      let ids = [];
      this.item.forEach((el) => {
        ids.push(el._id);
      });
      try {
        const params = {
          users_ids: ids,
          wallet: this.computedPrice,
        };
        const { data } = await axios.post("/add_limit", params);
        if (!!data.status) {
          this.successRequest = true;
          this.usersSuccess = [];
          const { users_success } = data;
          users_success.forEach(({ user }) => {
            let obj = {
              unique_id: user.unique_id,
              full_name: `${user.first_name} ${user.last_name}`,
              picture: user.picture,
              initials: `${user.first_name[0]}${user.last_name[0]}`,
              phone: `${user.country_phone_code}${user.phone}`,
              email: user.email,
              balance:
                this.handleUserStatus(user, data.corporate_id) === "Activo"
                  ? user.corporate_wallet_limit
                    ? user.corporate_wallet_limit
                    : 0
                  : "--",
              status: this.handleUserStatus(user, data.corporate_id),
              overdraft:
                this.handleUserStatus(user, data.corporate_id) === "Activo"
                  ? user.is_allow_overdraft
                    ? true
                    : false
                  : "--",
              _id: user._id,
            };
            this.usersSuccess.push(obj);
          });
          this.usersFail = [];
          const { users_failed } = data;
          users_failed.forEach((el) => {
            let obj = {
              unique_id: el.user.unique_id,
              full_name: `${el.user.first_name} ${el.user.last_name}`,
              picture: el.user.picture,
              initials: `${el.user.first_name[0]}${el.user.last_name[0]}`,
              phone: `${el.user.country_phone_code}${el.user.phone}`,
              email: el.user.email,
              balance:
                this.handleUserStatus(el.user, data.corporate_id) === "Activo"
                  ? el.user.corporate_wallet_limit
                    ? el.user.corporate_wallet_limit
                    : 0
                  : "--",
              status: this.handleUserStatus(el.user, data.corporate_id),
              overdraft:
                this.handleUserStatus(el.user, data.corporate_id) === "Activo"
                  ? el.user.is_allow_overdraft
                    ? true
                    : false
                  : "--",
              _id: el.user._id,
              reason_error_code: el.reason_error_code,
            };
            this.usersFail.push(obj);
          });
        } else {
          throw data.error_code;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    handleUserStatus(user, corporate_id) {
      return "Activo";
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft"]),
    ES() {
      return this.$vuetify.lang.current === "es";
    },
    computedDisabled() {
      if (this.addBalance) {
        if (this.wallet - this.userBalancePayload < 0) {
          return true;
        }
      }
      return false;
    },
    usersSumBalance() {
      const sum = this.item.reduce((accumulator, object) => {
        return accumulator + object.balance;
      }, 0);
      return sum;
    },
    usersSumBalanceAddCut() {
      let arr = [];
      this.item.forEach((el) => arr.push(el));
      let arr2 = [];
      arr.forEach((el) => arr2.push(el.add_cut));
      return arr2;
    },
    computedPrice() {
      let price = this.addBalance
        ? this.price.length >= 8
          ? parseFloat(this.price.replace(/,/g, ""))
          : parseFloat(this.price)
        : (this.price.length >= 8
            ? parseFloat(this.price.replace(/,/g, ""))
            : parseFloat(this.price)) * -1;
      return price;
    },
  },
  watch: {
    computedPrice: function () {
      this.item.forEach(
        (el) =>
          (el.add_cut = this.addBalance
            ? this.computedPrice
            : Math.abs(this.computedPrice) > el.balance
            ? el.balance * -1
            : this.computedPrice)
      );
      this.item.forEach((el) => {
        let difference = el.balance + el.add_cut;
        el.add_cut_balance = difference > 0 ? difference : 0;
      });
      this.userBalancePayload = this.item.reduce((accumulator, object) => {
        return accumulator + object.add_cut;
      }, 0);
    },
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    min-height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px

.dataTable
  tbody tr:nth-of-type(even)
    background-color: rgba(58, 53, 65, 0.04) !important

  tbody tr:hover
    background-color: rgba(0, 5, 34, 0.08) !important

  tbody tr
    border-bottom: hidden !important
</style>
