<template>
  <span>
    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :disable-sort="!true"
      :items="items"
      class="dataTableUsers elevation-1"
      item-key="unique_id"
      :search="search"
      mobile-breakpoint="0"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      }"
      v-model="selectedUsers"
      :single-select="false"
      :show-select="bulkOps"
    >
      <!-- show-select -->
      <template v-slot:top>
        <v-row dense class="mb-1 d-flex flex-row align-baseline">
          <v-col cols="12" md="6" class="d-flex flex-row align-center">
            <v-switch
              v-model="bulkOps"
              :disabled="loading"
              v-if="
                selectionFilter.length === 1 &&
                selectionFilter[0] == [$t('Active')]
              "
              dense
              hide-details
              class="ml-md-3"
            >
              <template #label>
                <span
                  class="text-caption text-md-subtitle-1"
                  :class="
                    !$vuetify.theme.isDark
                      ? 'color-blue-text'
                      : 'color-white-text'
                  "
                >
                  <span class="ml-1">
                    <v-icon>
                      {{
                        bulkOps
                          ? icons.mdiAccountMultipleOutline
                          : icons.mdiAccountOutline
                      }}
                    </v-icon>
                  </span>
                </span>
              </template>
            </v-switch>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-row align-baseline justify-end"
            :class="!$vuetify.breakpoint.mdAndUp && 'text-caption'"
          >
            <!-- placeholder para poder dar espacio -->
            <span class="d-flex flex-row align-center">
              <span :class="!$vuetify.theme.isDark && 'color-blue-text'">
                {{ $t("Total users") }}:
                <span class="font-weight-bold">{{
                  items && items.length
                }}</span>
              </span>
              <!-- cantidad de usuarios seleccionados -->
              <span v-if="selectedUsers.length > 0" class="ml-1">
                <v-menu
                  offset-y
                  :min-width="$vuetify.breakpoint.mdAndUp ? 220 : 220"
                  :max-height="420"
                  rounded="b-xl"
                  :nudge-left="350"
                  :close-on-click="true"
                  :close-on-content-click="false"
                  ref="userMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-if="selectedUsers.length === 1">
                      ( {{ selectedUsers.length }}
                      {{ ES ? "usuario seleccionado" : "user selected" }} )
                    </span>
                    <span v-else>
                      ( {{ selectedUsers.length }}
                      {{ ES ? "usuarios seleccionados" : "users selected" }} )
                    </span>
                    <span>
                      <br v-if="!$vuetify.breakpoint.mdAndUp">
                      <v-btn icon v-bind="attrs" v-on="on" :x-small="!$vuetify.breakpoint.mdAndUp">
                        <v-icon>
                          {{ icons.mdiMagnify }}
                        </v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <v-list class="py-0">
                    <v-subheader class="d-flex flex-row justify-space-between">
                      <h3>
                        {{ ES ? "Usuarios seleccionados" : "Selected users" }}
                      </h3>
                      <span>
                        <v-btn icon @click="handleCloseUserMenu()"
                          ><v-icon>{{ icons.mdiClose }}</v-icon></v-btn
                        >
                      </span>
                    </v-subheader>
                    <v-divider class="mb-3"></v-divider>
                    <template v-for="item in selectedUsers">
                      <v-list-item v-bind:key="item.unique_id" class="my-4">
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="10">
                              <v-list-item-title>{{
                                item.full_name
                              }}</v-list-item-title>
                              <v-list-item-subtitle class="">
                                <span>
                                  {{ item.phone }}
                                </span>
                                <span> | </span>
                                <span>
                                  {{ item.email }}
                                </span>
                                <br />
                                <span>
                                  {{ item.balance | currency }}
                                </span>
                                <span> | </span>
                                <span>
                                  {{ ES ? "Sobregiro:" : "Overdraft:" }}
                                  {{
                                    item.overdraft
                                      ? ES
                                        ? "Activo"
                                        : "Active"
                                      : ES
                                      ? "Inactivo"
                                      : "Inactive"
                                  }}
                                </span>
                              </v-list-item-subtitle>
                            </v-col>
                            <v-col cols="2">
                              <span>
                                <v-btn
                                  icon
                                  @click="handleRemoveUserMenu(item.unique_id)"
                                  ><v-icon>{{
                                    icons.mdiCheckboxMarked
                                  }}</v-icon></v-btn
                                ></span
                              >
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </template>
                  </v-list>
                </v-menu>

                <!-- menu de opciones de sobregiro -->
                <span class="ml-4">
                  <v-menu rounded=".rounded-lg" offset-y>
                    <!-- :nudge-left="150" -->
                    <template v-slot:activator="{ attrs, on }">
                      <!-- class="white--text ma-5" -->
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        :disabled="selectedUsers.length <= 1"
                        @click="handleCloseUserMenu()"
                        :x-small="!$vuetify.breakpoint.mdAndUp"
                      >
                        <v-icon>
                          {{ icons.mdiDotsVertical }}
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item link @click="handleBulkOverdraft()">
                        <v-list-item-title>
                          <span>{{ ES ? "Sobregiro" : "Overdraft" }} </span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="handleBulkSaldo()">
                        <v-list-item-title>
                          <span>{{ ES ? "Saldo" : "User balance" }} </span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="handleBulkUsersDelete()">
                        <v-list-item-title>
                          <span>{{ ES ? "Eliminar" : "Delete" }} </span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </span>
            </span>
            <br>

            <span class="ml-2">
              <v-tooltip top :max-width="300">
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="primary"
                    @click="handleRefresh()"
                    small
                  >
                    <v-icon>
                      {{ icons.mdiRefresh }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("Refresh table") }}
                </span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
      </template>
      <!-- <template #item="{ item }">
        <tr>
          <td v-for="(cell, i) in item" :key="i">
            <span v-if="i !== 10">
              {{ cell }}
            </span>
            <span v-else>

            </span>
          </td>
        </tr>
      </template> -->
      <template #item.full_name="{ item }">
        <span v-if="item.status === $t('Active')">
          <a
            target="_blank"
            :href="`/user/${item._id}`"
            style="text-decoration: none"
          >
            <v-avatar color="success" size="30">
              <span class="d-flex align-center justify-center">
                <v-img
                  :src="`${bucketUrl}${item.picture}`"
                  :alt="item.initials"
                  width="30"
                  v-if="item.picture"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
                <span v-else class="color-blue-text">
                  {{ item.initials }}
                </span>
              </span>
            </v-avatar>
            <span class="ml-1">
              {{ item.full_name }}
            </span>
          </a>
        </span>
        <span v-else>
          {{ item.full_name }}
        </span>
      </template>
      <template #item.balance="{ item }">
        <span
          v-if="item.balance !== '--'"
          :class="tableCellBalance(item.balance)"
        >
          {{ item.balance | currency }}</span
        >
        <span v-else> {{ item.balance }} </span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item.status === $t('Active')"
          class="v-chip-light-bg success--text"
          color="accent"
        >
          {{ item.status }}
        </v-chip>
        <v-chip
          v-if="item.status === $t('Inactive')"
          class="v-chip-light-bg error--text"
          color="accent"
        >
          {{ item.status }}
        </v-chip>
        <span v-if="item.status === $t('Waiting for approval')">
          <v-chip class="v-chip-light-bg warning--text" color="warning">
            {{ item.status }}
          </v-chip>
          <v-tooltip top :max-width="400">
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" medium v-on="on">
                {{ icons.mdiInformationOutline }}
              </v-icon>
            </template>
            <span>
              {{ item.full_name }}
              {{ $t("must accept the request in Ridery's user app") }}
            </span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.overdraft="{ value }">
        <v-chip
          v-if="value === true"
          class="v-chip-light-bg success--text"
          color="accent"
        >
          {{ $t("Active") }}
        </v-chip>
        <v-chip
          v-if="value === false"
          class="v-chip-light-bg error--text"
          color="accent"
        >
          {{ $t("Inactive") }}
        </v-chip>
        <span v-if="value === '--'"> -- </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu rounded=".rounded-lg" offset-y :disabled="loading || bulkOps">
          <template v-slot:activator="{ attrs, on }">
            <!-- class="white--text ma-5" -->
            <v-btn v-bind="attrs" v-on="on" icon :disabled="loading || bulkOps">
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              @click="toggleBalanceModal([item])"
              v-if="item.status === $t('Active')"
            >
              <v-list-item-title>{{ $t("user_wallet") }}</v-list-item-title>
            </v-list-item>
            <v-list-item link v-if="item.status === $t('Active')">
              <v-list-item-title @click="toggleOverdraftModal([item])">{{
                !item.overdraft
                  ? $t("Activate overdraft")
                  : $t("Deactivate overdraft")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="handleShowUser([item])"
              v-if="item.status !== $t('Inactive')"
            >
              <v-list-item-title>{{ $t("View user") }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="toggleDeleteModal([item])"
              v-if="item.status !== $t('Inactive')"
            >
              <v-list-item-title>{{ $t("Eliminate user") }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="toggleAddModal([item])"
              v-if="item.status === $t('Inactive')"
            >
              <v-list-item-title>{{ $t("Activate user") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <!-- <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize"> Reset </v-btn>
                </template> -->
    </v-data-table>
  </span>
</template>
<script>
import DeleteModal from "./DeleteModal.vue";
import DeleteModalEN from "./DeleteModalEN.vue";
import OverdraftModal from "./OverdraftModal.vue";
import OverdraftModalEN from "./OverdraftModalEN.vue";
import BalanceModal from "./BalanceModal.vue";
import BalanceModalEN from "./BalanceModalEN.vue";
import AddModal from "./AddModal.vue";
import AddModalEN from "./AddModalEN.vue";
import BulkOverdraftModal from "./BulkOverdraftModal.vue";
import BulkSaldoModal from "./BulkSaldoModal.vue";
import BulkDeleteModal from "./BulkDeleteModal.vue";

import { mapActions, mapState } from "vuex";

import {
  mdiDotsVertical,
  mdiCurrencyUsd,
  mdiDeleteOutline,
  mdiRefresh,
  mdiInformationOutline,
  mdiClose,
  mdiCheckboxMarked,
  mdiMagnify,
  mdiAccountOutline,
  mdiAccountMultipleOutline,
} from "@mdi/js";
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
    search: {
      type: String,
    },
    selectionFilter: {
      type: Array,
    },
    refresher: {
      type: Boolean,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
        mdiDeleteOutline,
        mdiRefresh,
        mdiInformationOutline,
        mdiClose,
        mdiCheckboxMarked,
        mdiMagnify,
        mdiAccountOutline,
        mdiAccountMultipleOutline,
      },
      selectedUsers: [],
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      bulkOps: false,
    };
  },
  methods: {
    handleRefresh() {
      this.$emit("refresh");
    },
    async toggleDeleteModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item,
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          DeleteModal,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          DeleteModalEN,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      }
    },
    async toggleOverdraftModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item,
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          OverdraftModal,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          OverdraftModalEN,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      }
    },
    async toggleBalanceModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item,
      };
      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          BalanceModal,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          BalanceModalEN,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      }
    },
    async toggleAddModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item,
      };
      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(AddModal, params);
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          AddModalEN,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      }
    },
    handleShowUser(item) {
      let route = this.$router.resolve({
        name: "user",
        params: { id: item[0]._id },
      });
      window.open(route.href, "_blank").focus();
    },
    tableCellBalance(value) {
      if (value >= 0.01) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return ``;
    },
    async handleBulkOverdraft() {
      this.$refs.userMenu.save();
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: this.activeSelectedUsers,
        hideCancel: true,
        persistent: true,
      };
      const dialogInstance = await this.$dialog.showAndWait(
        BulkOverdraftModal,
        params
      );
      if (dialogInstance) {
        this.$emit("refresh");
      }
    },
    handleCloseUserMenu() {
      this.$refs.userMenu.save();
    },
    handleRemoveUserMenu(id) {
      this.selectedUsers.splice(
        this.selectedUsers.findIndex((item) => item.unique_id === id),
        1
      );
    },
    async handleBulkSaldo() {
      this.$refs.userMenu.save();
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        item: this.activeSelectedUsers,
        hideCancel: true,
        persistent: true,
      };
      const dialogInstance = await this.$dialog.showAndWait(
        BulkSaldoModal,
        params
      );
      if (dialogInstance) {
        this.$emit("refresh");
      }
    },
    async handleBulkUsersDelete() {
      this.$refs.userMenu.save();
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "80%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: this.activeSelectedUsers,
        hideCancel: true,
        persistent: true,
      };
      const dialogInstance = await this.$dialog.showAndWait(
        BulkDeleteModal,
        params
      );
      if (dialogInstance) {
        this.$emit("refresh");
      }
    },
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft"]),
    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
    activeSelectedUsers() {
      let filtered = this.selectedUsers.filter((el) => {
        return el.status === "Activo" || el.status === "Active";
      });
      return filtered.sort((a, b) =>
        a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
      );
    },
    noBulkRules() {
      return (
        this.selectionFilter.length === 1 &&
        this.selectionFilter[0] == [this.$t("Active")]
      );
    },
    ES() {
      return this.$vuetify.lang.current === "es";
    },
  },
  watch: {
    bulkOps: function () {
      this.selectedUsers = [];
    },
    noBulkRules: function (newVal) {
      if (!newVal) {
        this.selectedUsers = [];
        this.bulkOps = false;
      }
    },
    refresher: function () {
      // let arrIds = [];
      // let copySelectedUsers = this.selectedUsers
      this.selectedUsers = [];
      // copySelectedUsers.forEach((el) => arrIds.push(el.unique_id));
      // this.items.forEach((el) => {
      //   arrIds.forEach((it) => {
      //     if (it === el.unique_id) {
      //       this.selectedUsers.push(el);
      //     }
      //   });
      // });
    },
  },
};
</script>
<style lang="scss">
.dataTableUsers {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
.custom-margin {
  margin-bottom: 14px;
}
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: rgba(0, 5, 34, 0.08) !important;
}
.theme--light.background-list {
  background: rgba(0, 5, 34, 0.08) !important;
}
</style>

